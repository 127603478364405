<template>
  <v-row justify="center">
    <v-dialog
      v-model="shower"
      scrollable
      persistent
      max-width="500px"
      v-if="resolutionScreen >= 500"
    >
      <v-card>
        <v-card-title
          >{{ $t("uploadfolder.name") }} <v-spacer></v-spacer>
          <v-icon :color="color.theme" dark size="35">mdi-folder-plus</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mb-n6 mt-3">
          <v-text-field
            class="pa-4"
            prepend-icon="mdi-folder-plus"
            dense
            outlined
            :label="$t('uploadfolder.textfoldername')"
            v-model="foldername"
            :disabled="createprogress"
            :error-messages="foldernameErrors"
            required
            @input="$v.foldername.$touch()"
            @blur="$v.foldername.$touch()"
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="createprogress"
            color="error"
            outlined
            @click="$emit('closenoreload'), cleardata()"
            >{{ $t("uploadfolder.buttonclose") }}</v-btn
          >
          <v-btn
            class="white--text"
            :color="color.theme"
            :loading="createprogress"
            :disabled="!this.fn_checkSpecialChar()"
            @click="createfolder()"
            >{{ $t("uploadfolder.buttonupload") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- หน้าจอมือถือ -->
    <v-dialog
      v-model="shower"
      scrollable
      persistent
      :max-width="maxWidthOnMobile"
      content-class="rounded-lg"
      v-else
    >
      <v-card>
        <v-card-text class="pa-5">
          <v-layout class="pt-2 pb-1">
            <v-flex d-flex justify-center class="ml-8">
              <span :style="headerPage">{{ $t("uploadfolder.name") }}</span>
            </v-flex>
            <v-flex xs1 d-flex justify-end>
              <v-icon
                :color="$vuetify.theme.dark ? 'white' : color.theme"
                @click="$emit('closenoreload'), cleardata()"
                >mdi-close</v-icon
              >
            </v-flex>
          </v-layout>
          <span :style="titleText">{{
            $t("uploadfolder.textfoldername")
          }}</span>
          <v-text-field
            height="35px"
            class="mt-1 textFieldClass"
            dense
            outlined
            v-model="foldername"
            :disabled="createprogress"
            :error-messages="foldernameErrors"
            required
            @input="$v.foldername.$touch()"
            @blur="$v.foldername.$touch()"
          ></v-text-field>
        </v-card-text>
        <div class="text-center pb-4 mt-n2">
          <v-btn
            :style="btnAction"
            class="mr-6"
            height="40px"
            width="40%"
            color="red"
            outlined
            :disabled="createprogress"
            @click="$emit('closenoreload'), cleardata()"
            >{{ $t("uploadfolder.buttonclose") }}</v-btn
          >
          <v-btn
            height="40px"
            class="elevation-0"
            :style="btnAction"
            width="40%"
            :color="color.theme"
            dark
            @click="createfolder()"
            >{{ $t("uploadfolder.buttonupload") }}</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required, maxLength, email, helpers } from "vuelidate/lib/validators";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import { fil } from "date-fns/locale";
import checkCharacter from "@/globalFunctions/checkCharacter";

const SpecialCharacterForFoldernameValidator = helpers.regex(
  "SpecialCharacterForFoldername",
  checkCharacter.checkSpecialCharacterForFoldername()
);

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  mixins: [validationMixin],
  validations: {
    foldername: { required, SpecialCharacterForFoldernameValidator },
    hashtag: { required },
  },
  props: ["show", "parentfolder", "departmentid"],
  data: function() {
    return {
      checkopenfolder: false,
      listhashtag: [],
      foldername: "",
      createprogress: false,
      hashtag: "",
      datahashtag: [],
      checkhashtag: false,
      rules: {
        noSpecialChar: () =>
          this.fn_checkSpecialChar() ||
          // "A folder name cannot contain any of the following characters: \ / < > : ? * |",
          this.$t("uploadfolder.nospecialcharacter"),
      },
      payloadfolder: {},
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
          this.$emit("closeDrag");
          this.checkopenfolder = false;
          this.checkhashtag = false;
          this.datahashtag = [];
        }
        return this.show;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    foldernameErrors() {
      const errors = [];
      if (!this.$v.foldername.$dirty) {
      } else if (!this.$v.foldername.required) {
        errors.push(this.$t("uploadfolder.enternamefolder"));
      } else if (this.$v.foldername.$error) {
        errors.push(this.$t("uploadfolder.nospecialcharacter"));
      }
      return errors;
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    headerPage() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    titleText() {
      return "color:black; font-size: 14px; line-height: 24px;";
    },
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if (this.resolutionScreen >= 400) {
        return 600;
      } else {
        return 346;
      }
    },
    hashtagErrors() {
      let errors = [];
      if (this.$v.hashtag.$dirty === false) {
        return errors;
      } else if (this.$v.hashtag.required === false) {
        errors.push(this.$t("edithashtag.hashtagempty"));
        return errors;
      } else {
        let allow = true;
        let specialChar = [",", " ", "|"];

        for (let index = 0; index < specialChar.length; index++) {
          const element = specialChar[index];
          if (this.hashtag.includes(element)) {
            allow = false;
            break;
          } else {
            continue;
          }
        }
        if (allow === false) {
          errors.push(this.$t("edithashtag.nospecialcharacter"));
          return errors;
        } else {
          return errors;
        }
      }
    },
  },
  methods: {
    addhashtag() {
      this.datahashtag.push(this.hashtag);
      console.log("this.datahashtag", this.datahashtag);
      this.hashtag = "";
    },
    deletehashtag(index) {
      this.datahashtag.splice(index, 1);
      console.log("this.datahashtag", this.datahashtag);
    },
    fn_checkSpecialChar() {
      let allow = true;
      let specialChar = ["<", ">", ":", "*", "?", "\\", "|", "/", '"'];

      for (let index = 0; index < specialChar.length; index++) {
        const element = specialChar[index];
        if (this.foldername.includes(element)) {
          allow = false;
          break;
        }
      }
      // console.log("allow", allow);
      return allow;
    },
    async createfolder() {
      // console.log("test----------------------------------");
      this.$v.$touch();
      let payload;
      if (!this.$v.foldername.$invalid) {
        this.createprogress = true;
        console.log(this.parentfolder);
        console.log("this.datahashtag", this.datahashtag);
        let allowCreateFolder = this.fn_checkSpecialChar();
        if (allowCreateFolder) {
          let payloadcheck = {
            folder_id: this.parentfolder,
            data_name: this.foldername,
            data_type: "folder",
          };
          let statusduplicate;
          let authcheck = await gbfGenerate.generateToken();
          await this.axios
            .post(
              process.env.VUE_APP_SERVICE_UPLOAD_API +
                "/api/v1/check_duplicate_data",
              payloadcheck,
              { headers: { Authorization: authcheck.code } }
            )
            .then((response) => {
              if (response.data.message === "duplicate foldername") {
                // ชื่อซ้ำ
                statusduplicate = "duplicate";
              } else if (response.data.message === "unique foldername") {
                // ชื่อไม่ซ้ำ
                statusduplicate = "unique";
              } else {
                statusduplicate = "denied";
              }
            })
            .catch((error) => {
              console.log("error", error);
              statusduplicate = "denied";
            });
          if (statusduplicate === "unique") {
            // ยิงapi
            if (!this.payloadfolder.folder_name) {
              payload = {
                folder_name: this.foldername,
                user_id: this.dataUsername,
                id: this.parentfolder,
                account_id: this.dataAccountId,
                department_id: this.departmentid,
                // hashtag: this.datahashtag,
                folder_duplicate: this.fileduplicate,
                // department_id: this.dataDepartmentAccessId
              };
            } else {
              payload = this.payloadfolder;
              payload.folder_duplicate = this.fileduplicate;
            }
            let url;
            if (this.$route.name === "directorysharefile") {
              url =
                process.env.VUE_APP_SERVICE_UPLOAD_FOLDER +
                "/api/insert_folder_share";
            } else {
              url =
                process.env.VUE_APP_SERVICE_UPLOAD_FOLDER +
                "/api/v2/insert_folder";
            }
            let auth = await gbfGenerate.generateToken();
            await this.axios
              .post(url, payload, { headers: { Authorization: auth.code } })
              .then((response) => {
                if (response.data.status === "OK") {
                  Toast.fire({
                    icon: "success",
                    title: this.$t("uploadfolder.createsucess"),
                  });
                  this.cleardata();
                  this.listhashtag = [];
                  this.checkhashtag = false;
                  this.$emit("createsuccess", response.data.data);
                  this.$emit("closenoreload");
                } else {
                  Toast.fire({
                    icon: "error",
                    title: this.$t("uploadfolder.cannotcreate"),
                  });
                }
                this.createprogress = false;
              })
              .catch((error) => {
                console.log(error.response);
                Toast.fire({
                  icon: "error",
                  title: this.$t("uploadfolder.cannotcreate"),
                });
                this.createprogress = false;
              });
          } else if (statusduplicate === "duplicate") {
            Toast.fire({
              icon: "error",
              title: this.$t("uploadfolder.nameduplicate"),
            });
            this.createprogress = false;
          } else {
            Toast.fire({
              icon: "error",
              title: this.$t("uploadfolder.cannotcreate"),
            });
            this.createprogress = false;
          }
        } else {
          // แจ้งerror
          console.log("error createfolder", allowCreateFolder);
        }
      }
    },
    cleardata() {
      this.$v.$reset();
      this.foldername = "";
      this.createprogress = false;
      this.payloadfolder = {};
    },
  },
};
</script>
<style>
.v-input.textFieldClass input {
  font-size: 14px;
}
.v-text-field.textFieldClass .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
.v-input.hastaghClass input {
  font-size: 14px;
}
.v-text-field.hastaghClass .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
.v-input.hastaghClass input {
  font-size: 14px;
  max-height: 90px;
  padding-bottom: 11px;
  padding-top: 11px;
}
</style>
